import React, { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Flex, Text, Heading } from '@rebass/emotion';
import Footer from '~components/PageBlocks/Footer';
import Header from '~components/PageBlocks/Header/index';
import Sidebar from '~components/PageBlocks/Sidebar';
import RichTextcontent from '~components/PageBlocks/RichTextContent';
import Link from '~components/PageBlocks/Link';
import CmsPage from '../components/Elements/CmsPage';
import { PageProvider } from '../context/PageProvider';
import { useLocalStorage } from '../hooks/useLocalStorage';
import Icon from '~components/Elements/Icon';
import ArrowLeft from '~icons/arrow-left';
import ArrowRight from '~icons/arrow-right';

const App = styled(Flex)`
  flex-direction: column;
  justify-content: start;
  min-height: 100vh;
  max-width: 100vw;
  font-family: ${props => props.theme.fonts.sans};
`;

const ContentFlex = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 5vw;
  max-width: 816px;

  @media (max-width: ${props => props.theme.device.lg}) {
    margin-left: 0;
  }
`;

const SidebarFlex = styled(Flex)`
  font-family: ${props => props.theme.fonts.sans};
  width: auto;
  min-width: 300px;

  @media (max-width: ${props => props.theme.device.lg}) {
    display: none;
  }
`;

const PrevNextButton = ({
  page,
  url,
  type = 'prev',
  pathway = true,
  title = null,
}) => {
  const theme = useTheme();
  return (
    <Link
      data={{ page, url, pathway }}
      style={{ textDecoration: 'none', width: '100%' }}
    >
      <Flex
        flexDirection="column"
        justifyContent={type === 'prev' ? 'flex-start' : 'flex-end'}
        width="100%"
        mt={['20px', '20px', '20px', 0]}
      >
        {type === 'prev' ? (
          <Flex
            flexDirection="row"
            justifyContent="flex-start"
            mt={['30px', '30px', '30px', '30px', 0]}
          >
            <ArrowLeft
              width="16"
              height="16"
              color={theme.colors.neutralNeutral180}
            />
            <Text
              fontSize="14px"
              lineHeight="16px"
              style={{ textDecoration: 'underline' }}
              ml="8px"
              width="auto"
            >
              PREVIOUS
            </Text>
          </Flex>
        ) : (
          <Flex
            flexDirection="row"
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'flex-end',
            ]}
            width="100%"
          >
            <Text
              fontSize="14px"
              lineHeight="16px"
              style={{ textDecoration: 'underline' }}
              mr="8px"
              width="auto"
            >
              NEXT
            </Text>
            <ArrowRight
              width="16"
              height="16"
              color={theme.colors.neutralNeutral180}
            />
          </Flex>
        )}
        <Text
          fontSize={['16px', '16px', '16px', '20px']}
          lineHeight={['24px', '24px', '24px', '28px']}
          fontWeight="500"
          color="#1a3092"
          mt="8px"
          textAlign={
            type === 'prev' ? 'left' : ['left', 'left', 'left', 'right']
          }
        >
          {title || page.title}
        </Text>
      </Flex>
    </Link>
  );
};

const PrevNext = ({ menu, article }) => {
  const reducer = (allPages, item) => {
    if (item.page?._type === 'contentPage') {
      allPages.push(item.page);
    }

    if (item.items?.length) {
      allPages = [...allPages, ...item.items.reduce(reducer, [])];
    }

    return allPages;
  };

  const pages = menu.items.reduce(reducer, []);
  const currentPageIndex = pages.findIndex(page => page._id === article._id);
  const prevPage = currentPageIndex === 0 ? null : pages[currentPageIndex - 1];
  const nextPage =
    currentPageIndex === pages.length - 1 ? null : pages[currentPageIndex + 1];

  return (
    <Flex
      flexDirection={[
        'column-reverse',
        'column-reverse',
        'column-reverse',
        'row',
      ]}
      justifyContent={['center', 'center', 'center', 'space-between']}
      alignItems="center"
      width="100%"
      mt={['20px', '20px', '20px', '24px']}
    >
      {prevPage ? <PrevNextButton page={prevPage} type="prev" /> : <div />}
      {nextPage ? (
        <PrevNextButton page={nextPage} type="next" />
      ) : (
        <PrevNextButton
          url={`/explore/${article.topic.section}`}
          type="next"
          title="Complete pathway"
          pathway={false}
        />
      )}
    </Flex>
  );
};

const PrevNextTop = ({ menu, article }) => {
  const theme = useTheme();
  const reducer = (allPages, item) => {
    if (item.page?._type === 'contentPage') {
      allPages.push(item.page);
    }

    if (item.items?.length) {
      allPages = [...allPages, ...item.items.reduce(reducer, [])];
    }

    return allPages;
  };

  const pages = menu.items.reduce(reducer, []);
  const currentPageIndex = pages.findIndex(page => page._id === article._id);
  const prevPage = currentPageIndex === 0 ? null : pages[currentPageIndex - 1];
  const nextPage =
    currentPageIndex === pages.length - 1 ? null : pages[currentPageIndex + 1];

  const PrevNextContainer = styled(Flex)`
    border-bottom: 1px solid #33333320;

    @media (min-width: ${props => props.theme.device.lg}) {
      display: none;
    }
  `;

  return (
    <PrevNextContainer
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      px="5vw"
      py="16px"
    >
      {prevPage ? (
        <Link
          data={{ page: prevPage, pathway: true }}
          style={{
            textDecoration: 'underline',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Icon name="breadcrumb-chevron-left" mr="8px" />
          <Text fontSize="14px" lineHeight="16px">
            Previous
          </Text>
        </Link>
      ) : (
        <div />
      )}
      <p>Navigate Your Pathway</p>
      {nextPage ? (
        <Link
          data={{ page: nextPage, pathway: true }}
          style={{
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Text fontSize="14px" lineHeight="16px">
            Next
          </Text>
          <Icon name="breadcrumb-chevron-right" ml="8px" />
        </Link>
      ) : (
        <Link
          data={{ url: `/explore/${article.topic.section}`, pathway: false }}
          style={{
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Text fontSize="14px" lineHeight="16px">
            Next
          </Text>
          <Icon name="breadcrumb-chevron-right" ml="8px" />
        </Link>
      )}
    </PrevNextContainer>
  );
};

const AutismPathway = ({ pageContext, data }) => {
  pageContext.article = data.sanityContentPage;

  const [pathwayStage, setPathwayStage] = useLocalStorage(
    'pathway-stage',
    null
  );
  const [pathwaySection, setPathwaySection] = useLocalStorage(
    'pathway-section',
    null
  );

  const [pathwayPages, setPathwayPages] = useLocalStorage('pathway-pages', []);

  useEffect(() => {
    if (!pathwayPages.find(p => p === pageContext.article._id)) {
      setPathwayPages([...pathwayPages, pageContext.article._id]);
    }

    const stage = pageContext.menu.items.findIndex(item => {
      if (item.page?._id === pageContext.article._id) {
        return true;
      }

      if (
        item.items.find(subItem => {
          if (subItem.page?._id === pageContext.article._id) {
            return true;
          }
          if (
            (subItem?.items || []).find(subSubItem => {
              return subSubItem.page?._id === pageContext.article._id;
            })
          ) {
            return true;
          }
        })
      ) {
        return true;
      }

      return false;
    });

    setPathwaySection(pageContext.article.topic.section);
    setPathwayStage(stage + 1);
  }, [pageContext, pathwayPages]);

  return (
    <CmsPage pageContext={pageContext}>
      <PageProvider page={pageContext}>
        <App>
          <header>
            <Header data={pageContext.header}></Header>
          </header>
          <PrevNextTop menu={pageContext.menu} article={pageContext.article} />
          <main>
            <Flex padding={'5vh 5vw'}>
              <SidebarFlex>
                <Sidebar data={pageContext.menu} page={pageContext.article} />
              </SidebarFlex>
              <ContentFlex>
                <Heading
                  as="h1"
                  fontSize="44px"
                  fontWeight="bold"
                  color={'blueBlue160'}
                  textAlign="left"
                >
                  {pageContext.article.title}
                </Heading>

                <Text
                  fontSize="20px"
                  lineHeight="28px"
                  color={'neutralNeutral170'}
                  textAlign="left"
                  mt="30px"
                  mb="50px"
                >
                  {pageContext.article.description}
                </Text>

                <RichTextcontent
                  data={{ blockContent: pageContext.article.body }}
                  px="0px"
                />
                <PrevNext
                  menu={pageContext.menu}
                  article={pageContext.article}
                />
              </ContentFlex>
            </Flex>
          </main>

          <Footer data={pageContext.footer}></Footer>
        </App>
      </PageProvider>
    </CmsPage>
  );
};

export default AutismPathway;

export const query = graphql`
  query PathwayArticleQuery($articleId: String) {
    sanityContentPage(_id: { eq: $articleId }) {
      _id
      _type
      name
      title
      description
      slug {
        _key
        _type
        current
      }
      topic {
        _id
        _type
        title
        description
        section
        slug {
          _key
          _type
          current
        }
      }
      body: _rawBody(resolveReferences: { maxDepth: 20 })
      image {
        crop {
          top
          left
          bottom
          right
        }
        hotspot {
          height
          width
          x
          y
        }
        asset {
          url
          path
          title
          description
          altText
        }
      }
      relatedContent {
        ... on SanityContentPage {
          _id
          _type
          name
          title
          description
          slug {
            current
          }
          topic {
            _id
            section
            slug {
              current
            }
          }
          image {
            crop {
              top
              left
              bottom
              right
            }
            hotspot {
              height
              width
              x
              y
            }
            asset {
              url
              path
              title
              description
              altText
            }
          }
        }
      }
    }
  }
`;
